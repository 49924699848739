
/**
 * @name: 小程序人气教练
 * @author: itmobai
 * @date: 2023-06-03 10:22
 * @description：小程序人气教练
 * @update: 2023-06-03 10:22
 */
import {Component, Vue} from "vue-property-decorator";
import config from "@/config";
import {
  recommendtourQueryRecommendTourListApi,
  recommendContestBatchDeleteApi,
  recommendContestCreateApi,
  recommendContestModifyApi,
  recommendContestUpAndDownShelfApi
} from "@/apis/marketingManage/coach"
import { IMarketCoach } from "@/apis/marketingManage/coach/types";
import {ICrudOption} from "@/types/m-ui-crud";
import {deepCopy} from "@/utils/common";
import {queryCourseListApi} from "@/apis/coursesManage/courses";

@Component({})
export default class MarketTravelPage extends Vue {
  config = config
  // 表格数据
  tableData: IMarketCoach[] = []
  // 选择的数据
  selectIds: string[] = []
  // 添加弹窗
  addDialog: boolean = false;

  addTableTotal: number = 0
  list: any = []
  query: any = {
    page: 1,
    limit: 10,
    status: 1
  }
  modelForm: any = {}
  modelFormSort: number = 1

  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: 'ID',
        prop: 'id',
        width: 180,
        align: 'center'
      },
      {
        label: '课程名称',
        prop: 'name',
        align: 'center',
        overHidden: true,
        search: true
      },
      {
        label: '教练',
        prop: 'coachUserName',
        align: 'center',
        overHidden: true,
        search: true
      },
      {
        label: '课程售价',
        prop: 'price',
        align: 'center',
        width: 150
      }
    ]
  }

  addSelectRows: any = []

  /**
   * 查询列表
   */
  getList () {
    recommendtourQueryRecommendTourListApi().then(e => {
      this.tableData = e;
    })
  }

  avatarUploadSuccess (id: string, key: string, res: any, file: any) {
    const { url } = res.data;
    const obj: Partial<IMarketCoach> = {id}
    // @ts-ignore
    obj[key] = url;
    recommendContestModifyApi(obj).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  beforeAvatarUpload () {

  }

  /**
   * 更新列表配置
   * @param id
   * @param key
   * @param value
   */
  updateRow (id: string, key: string, value: string) {
    if (key === 'sort') {
      if (!/^[1-9]\d*$/.test(value)) {
        return this.$message.error("请输入正整数")
      }
    }
    const obj: Partial<IMarketCoach> = {id}
    // @ts-ignore
    obj[key] = value;
    recommendContestModifyApi(obj).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  handleSelectionChange (val: any) {
    this.selectIds = val.map((item: any) => item.id)
  }

  batchDelete (ids: string[]) {
    if (!ids.length) {
      return this.$message.error("请选择数据")
    }
    this.$confirm('是否确认移除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      recommendContestBatchDeleteApi(ids.join(",")).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  handleSwitch (id: string, val: number) {
    recommendContestUpAndDownShelfApi(id, val).then(e => {
      this.$message.success("操作成功!")
      this.getList()
    })
  }

  openAddDialog () {
    this.modelFormSort = 1
    this.query.page = 1
    this.query.limit = 10
    this.list = []
    this.addSelectRows = []
    this.addDialog = true
    this.getMatchList()
  }

  getMatchList () {
    this.query.courseTypes = 3
    queryCourseListApi(this.query).then(e => {
      this.addTableTotal = e.total;
      this.list = e.list;
    })
  }

  handleChoose () {
    if (!this.addSelectRows.length) {
      return this.$message.error("请选择数据")
    }
    if (!/^[1-9]\d*$/.test(String(this.modelFormSort))) {
      return this.$message.error("请输入正整数")
    }
    const obj: any = deepCopy(this.addSelectRows[0])
    obj.courseId = this.addSelectRows[0].id;
    obj.sort = this.modelFormSort
    delete obj.id
    recommendContestCreateApi(obj).then(e => {
      this.$message.success("操作成功!")
      this.getList()
      this.addDialog = false
    })
  }

  created () {
    this.getList()
  }
}
